import React from "react";
import { graphql } from "gatsby";

import HeroHeading from "../components/HeroHeading";
import Team from "../components/Team";
import ViewNext from "../components/ViewNext";
import HelmetInfo from "../components/HelmetInfo";

const ExecutiveLeadership = ({ data }) => {
  const { heading, subheading, team } = data.prismicExecutiveLeadership.data;
  return (
    <>
      <HelmetInfo page_data={data.prismicExecutiveLeadership.data} />
      <HeroHeading heading={heading} subheading={subheading} />
      <Team team={team} fromExecutive={true} />
      <ViewNext
        link="/board-of-directors"
        linkText="Board of Directors"
        borderTop
      />
    </>
  );
};

export default ExecutiveLeadership;

export const query = graphql`
  query ExecLeadQuery {
    prismicExecutiveLeadership {
      data {
        heading
        subheading
        team {
          team_member {
            document {
              ... on PrismicPerson {
                uid
                data {
                  name
                  position
                  image {
                    url
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
